<template>
  <div class="select-buttons">
    <button
      v-for="option in options"
      :key="option.value"
      class="select-buttons__button"
      :class="{ 'm-selected': option.value === innerValue }"
      @click="innerValue = option.value"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SelectButtons",
  props: {
    value: {
      type: [String, Number, null],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;

  &__button {
    padding: 3px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $secondary-400;
    background-color: transparent;
    color: $secondary-400;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;

    &.m-selected {
      border-color: $secondary-100;
      color: $white;
      background: $primary;
    }
  }
}
</style>
